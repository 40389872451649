<template>
  <div>
    <b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <div class="row ml-2 mb-2" style="display: block ruby">
        <h4>Liste des catégories</h4>
        <b-button
          type="submit"
          class="btn ml-2"
          variant="outline-primary"
          @click="RedirectToAddCategory"
        >
          Ajouter
        </b-button>
      </div>

      <div>
        <b-table
          :items="items.categories"
          :fields="fields"
          striped
          hover
          responsive
          :busy="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong style="margin-left: 5px">Loading...</strong>
            </div>
          </template>

          <template #cell(image)="row" style="width: 100px">
            <div v-if="row.item.image">
              <b-img :src="row.item.image.src" width="50"> </b-img>
            </div>
            <div v-else>
              <b-img :src="categorieImageNotFound" width="50"> </b-img>
            </div>
          </template>
          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <div class="text-nowrap">
              <feather-icon
                :id="`invoice-row-${data.item.id}-preview-icon`"
                :icon="data.detailsShowing ? 'EyeOffIcon' : 'EyeIcon'"
                size="16"
                class="mx-1"
                @click.stop="fetchSubCategorie(data.item.id)"
                @click="data.toggleDetails"
              />
              <b-tooltip
                title="Sous catégories"
                :target="`invoice-row-${data.item.id}-preview-icon`"
              />
              <feather-icon
                :id="`invoice-row-${data.item.id}-edit-icon`"
                icon="EditIcon"
                @click="RedirectToEditCategory(data.item.id)"
              />
              <b-tooltip
                title="Modification"
                :target="`invoice-row-${data.item.id}-edit-icon`"
              />
              <feather-icon
                :id="`invoice-row-${data.item.id}-quick-edit-icon`"
                icon="EditIcon"
                v-b-modal.modal-1
                @click="getCategorieInfo(data.item)"
                class="mx-1"
              />
              <b-tooltip
                title="Modification rapide"
                :target="`invoice-row-${data.item.id}-quick-edit-icon`"
              />

              <feather-icon
                :id="`invoice-row-${data.item.id}-trash-icon`"
                icon="TrashIcon"
                @click="deleteAction(data.item.id)"
              />
              <b-tooltip
                title="Supprimer"
                :target="`invoice-row-${data.item.id}-trash-icon`"
              />
            </div>
          </template>
          <template #row-details="row">
            <b-row class="mb-2">
              <b-table
                :items="subCatagories"
                :fields="fields"
                striped
                hover
                responsive
                :busy="loadSubCategory"
              >
                <template #table-busy>
                  <div class="text-center text-primary my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong style="margin-left: 5px">Loading...</strong>
                  </div>
                </template>
                <template #cell(image)="row" style="width: 100px">
                  <div v-if="row.item.image">
                    <b-img :src="row.item.image.src" width="50"> </b-img>
                  </div>
                  <div v-else>
                    <b-img :src="categorieImageNotFound" width="50"> </b-img>
                  </div>
                </template>
                <template #cell(actions)="data">
                  <div class="text-nowrap">
                    <feather-icon
                      :id="`invoice-row-${data.item.id}-edit-icon`"
                      icon="EditIcon"
                      @click="RedirectToEditCategory(data.item.id)"
                    />
                    <b-tooltip
                      title="Modification"
                      :target="`invoice-row-${data.item.id}-edit-icon`"
                    />
                    <feather-icon
                      :id="`invoice-row-${data.item.id}-quick-edit-icon`"
                      icon="EditIcon"
                      v-b-modal.modal-1
                      @click="getCategorieInfo(data.item)"
                      class="mx-1"
                    />
                    <b-tooltip
                      title="Modification rapide"
                      :target="`invoice-row-${data.item.id}-quick-edit-icon`"
                    />

                    <feather-icon
                      :id="`invoice-row-${data.item.id}-trash-icon`"
                      icon="TrashIcon"
                      @click="deleteAction(data.item.id)"
                    />
                    <b-tooltip
                      title="Supprimer"
                      :target="`invoice-row-${data.item.id}-trash-icon`"
                    />
                  </div>
                </template>
              </b-table>
            </b-row>
          </template>
        </b-table>
        <b-pagination
          class="mt-3"
          align="center"
          v-model="currentPage"
          :per-page="perPage"
          :total-rows="items.totalCategories"
          @change="actualisationCategory"
        ></b-pagination>
      </div>
      <div>
        <QuickEdit
          v-if="showModal"
          :showModal="showModal"
          :name="categorieInfo.name"
          :slug="categorieInfo.slug"
          @ConfirmEdit="quickUpdateCategorie"
        />
      </div>
    </b-overlay>
  </div>
</template>

<script>
import {
  BButton,
  BIcon,
  BFormCheckbox,
  BCard,
  BRow,
  BCol,
  BBadge,
  BAvatar,
  BTable,
  BSpinner,
  BImg,
  BLink,
  BTooltip,
  BOverlay,
  BDropdown,
  BDropdownItem,
  BFormSelect,
  BFormInput,
  BPagination,
} from "bootstrap-vue";
import axios from "axios";
import websiteURL from "@/websiteURL";
import categorieImageNotFound from "../../assets/images/logo/woocommerce-placeholder.png";
import { fetchCategorie } from "../../views/Categories/CategoryFunction.js";
import QuickEdit from "../../views/QuickEditModal/QuickEdit.vue";
export default {
  components: {
    BSpinner,
    BTooltip,
    BDropdownItem,
    BDropdown,
    BIcon,
    BTable,
    BImg,
    BLink,
    BButton,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BBadge,
    BAvatar,
    BFormSelect,
    BFormInput,
    BOverlay,
    BPagination,
    QuickEdit,
  },
  data() {
    return {
      isBusy: false,
      mainProps: {
        width: 75,
        height: 75,
        class: "m1",
      },
      items: [],
      fields: [
        {
          label: "Image",
          key: "image",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          label: "Name",
          key: "name",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          label: "Slug",
          key: "slug",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          label: "Total produits",
          key: "count",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          label: "Actions",
          key: "actions",
          thClass: "text-center",
          tdClass: "text-center",
        },
      ],

      categorieImageNotFound: categorieImageNotFound,
      categorieInfo: [],
      boxMsgDelete: "",
      loading: false,
      subCatagories: [],
      perPage: 5,
      currentPage: 1,
      loadSubCategory: false,
      nomCategory: "",
      slugCategory: "",
      showModal: false,
    };
  },
  methods: {
    async RedirectToEditCategory(item) {
      this.$router.push({
        name: "Edit Categorie",
        params: { id: item },
      });
    },
    async RedirectToAddCategory() {
      this.$router.push({
        name: "Add Category",
        params: {},
      });
    },
    //Permet de recuperer les informations d'une categorie au clique sur icon modification rapide

    async getCategorieInfo(item) {
      this.categorieInfo = item;
      this.nomCategory = this.categorieInfo.name;
      this.slugCategory = this.categorieInfo.slug;
      this.showModal = !this.showModal;
    },
    //Permet la modification rapide d'une catégorie
    async quickUpdateCategorie(infoCategorie) {
      const apiUrl =
        websiteURL.WEBSITE +
        `wp-json/wc/v3/products/categories/${this.categorieInfo.id}`;
      const consumerKey = websiteURL.CONSUMERKEY;
      const consumerSecret = websiteURL.CONSUMERSECRET;
      const data = {
        name: infoCategorie.name,
        slug: infoCategorie.slug,
      };
      try {
        const response = await axios.put(apiUrl, data, {
          auth: {
            username: consumerKey,
            password: consumerSecret,
          },
        });
        await this.actualisationCategory();
        this.currentPage = 1;
      } catch (error) {
        // Handle errors, e.g., show an error message
        alert("Erreur lors de la mise à jour de la categorie:", error);
      }
    },

    deleteAction(idCategorie) {
      this.categoryId = idCategorie;
      this.showMsgBox();
    },
    //Permet la suppression d'une catégorie
    async deleteCategorie() {
      const apiUrl =
        websiteURL.WEBSITE +
        `wp-json/wc/v3/products/categories/${this.categoryId}`;
      const consumerKey = websiteURL.CONSUMERKEY;
      const consumerSecret = websiteURL.CONSUMERSECRET;
      try {
        const response = await axios.delete(apiUrl, {
          params: {
            force: true,
          },
          auth: {
            username: consumerKey,
            password: consumerSecret,
          },
        });
        this.makeToastMsg("info");
        this.currentPage = 1;
      } catch (error) {
        alert(
          "Erreur lors de la suppression de la catégorie, veuillez réessayer plus tard",
          error
        );
      }
    },
    //Message pour confirmer ou non la suppression d'une catégorie
    showMsgBox() {
      this.boxMsgDelete = "";
      this.$bvModal
        .msgBoxConfirm("Êtes-vous sûr de vouloir supprimer la catégorie ?", {
          title: "Confirmation",
          size: "sm",
          okVariant: "primary",
          okTitle: this.loading ? "Loading..." : "Confirmer",
          cancelTitle: "Annuler",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          this.boxMsgDelete = value;
          if (value) {
            if (value) {
              this.loading = true;
              this.deleteCategorie().then(async () => {
                this.loading = false;
                await this.actualisationCategory();
              });
            }
          }
        });
    },
    makeToastMsg(variant = null) {
      this.$bvToast.toast("La catégorie a bien été supprimer", {
        title: `Catégorie ${variant || "default"}`,
        variant,
        solid: true,
        href: this.permalink,
      });
    },
    //Permet de recuperer les sous categories d'une categorie
    async fetchSubCategorie(idCategorie) {
      this.loadSubCategory = true;
      const apiUrl = websiteURL.WEBSITE + `wp-json/wc/v3/products/categories`;
      const consumerKey = websiteURL.CONSUMERKEY;
      const consumerSecret = websiteURL.CONSUMERSECRET;
      try {
        const response = await axios.get(apiUrl, {
          params: {
            parent: idCategorie,
          },
          auth: {
            username: consumerKey,
            password: consumerSecret,
          },
        });

        this.loadSubCategory = false;
        this.subCatagories = response.data;
      } catch (error) {
        alert(
          "Erreur lors de la récuperation des sous catégories, veuillez réessayer plus tard",
          error
        );
      }
    },
    //Permet l'actualisation du tableau
    async actualisationCategory(event) {
      this.isBusy = true;
      if (event === null) {
        this.items = await fetchCategorie(this.perPage, this.currentPage);
      } else {
        this.items = await fetchCategorie(this.perPage, event);
      }
      this.isBusy = false;
    },
    //Permet de retablir la valeur initial des input au clique sur annuler
    annulerModificationRapide() {
      this.categorieInfo.name = this.nomCategory;
      this.categorieInfo.slug = this.slugCategory;
    },
  },
  async created() {
    await this.actualisationCategory();
  },
};
</script>
